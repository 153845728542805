



























































import { Component, Vue, Ref } from 'vue-property-decorator'

@Component({
  components: {
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconFrown: () => import('@/components/UI/icons/IconFrown.vue'),
    IconPlus: () => import('@tada/icons/dist/IconPlus.vue'),
  },
})
export default class DropZone extends Vue {
  @Ref() private readonly input!: HTMLInputElement
  private active = false
  private allowedExtensions = ['zip']
  private haveFileExtensionError = false
  private haveFileCountError = false

  private openFileSelector = false

  private get accept () {
    return this.allowedExtensions.map(e => '.' + e)
  }

  private selectFile (event: Event) {
    this.active = false
    this.haveFileExtensionError = false
    this.haveFileCountError = false

    const target = event.target as HTMLInputElement
    if (!target) return

    const files = target.files
    if (!files || !files.length) return

    if (files.length > 1) {
      this.haveFileCountError = true
      target.value = ''
      return
    }
    const file = files[0]

    if (!this.checkFileExtension(file.name)) {
      this.haveFileExtensionError = true
      return
    }
    this.$emit('upload', file)
    target.value = ''
  }

  private checkFileExtension (fileName: string): boolean {
    const splitFileName = fileName.split('.').reverse()
    if (splitFileName.length < 2 || !splitFileName[0]) return false
    return this.allowedExtensions.includes(splitFileName[0])
  }

  get label1 () {
    return this.haveFileExtensionError
      ? this.$t('import.step1.upload.textError').toString()
      : this.haveFileCountError
        ? this.$t('import.step1.upload.textCountError').toString()
        : this.$t('import.step1.upload.text').toString()
  }

  get label2 () {
    return this.haveFileExtensionError
      ? this.$t('import.step1.upload.warning').toString()
      : this.haveFileCountError
        ? this.$t('import.step1.upload.countWarning').toString()
        : this.$t('import.step1.upload.or').toString()
  }

  get buttonLabel () {
    return this.haveFileExtensionError || this.haveFileCountError
      ? this.$t('import.step1.upload.repeatLoad').toString()
      : this.$t('import.step1.upload.load').toString()
  }

  public uploadFile () {
    this.openFileSelector = true
    this.input.click()
  }

  private inputClick (event: Event) {
    if (this.openFileSelector) {
      this.openFileSelector = false
      return
    }
    event.preventDefault()
  }
}
